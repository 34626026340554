import _ from 'lodash';
import { Loader, LoaderResource } from 'pixi.js';

import type { IResource } from '@phoenix7dev/shared-components/dist/resources/d';

import { SlotId, config } from '../config';
import type { ISettledBet } from '../global';
import { setBetAmount, setCoinValue, setGameMode } from '../gql/cache';
import type { Features } from '../slotMachine/d';

import type { IPixiAssets } from './d';
import { isFreeSpinMode } from './helper';

export function randomInteger(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const wait = (ms: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const pixiLoad = (): Promise<Partial<Record<string, LoaderResource>>> => {
  return new Promise((resolve, reject) => {
    Loader.shared.load((_loader, resources) => {
      const failed = _.filter(resources, (resource) => !!resource?.error);
      if (failed.length) return reject(failed);
      return resolve(resources);
    });
  });
};

export const loadPixiAssets = (assets: IPixiAssets[]): Promise<void> => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    assets.forEach((asset) => Loader.shared.add(asset.name, asset.src));
    let tries = config.failureRetries;
    let success = false;

    while (tries > 0) {
      try {
        tries -= 1;
        await pixiLoad();
        success = true;
        break;
      } catch (err) {
        console.log(err);
      }
    }

    return success ? resolve() : reject();
  });
};

export const loadImages = async (
  assets: IterableIterator<[string, IResource]>,
  cb?: CallableFunction,
): Promise<void> => {
  let promises: Promise<IResource>[] = [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const [key, value] of assets) {
    promises.push(
      new Promise((resolve, reject) => {
        const asset: HTMLImageElement = new Image();
        asset.src = value.source;
        asset.onload = () => {
          if (cb) cb(value.key);
          resolve(value);
        };
        asset.onerror = () => reject(value);
      }),
    );
  }

  let tries = config.failureRetries;
  let success = false;

  while (tries > 0) {
    try {
      tries -= 1;
      const result = await Promise.allSettled(promises);
      const failed = _.filter(
        result,
        (asset: { status: string }) => asset.status === 'rejected',
      ) as PromiseRejectedResult[];

      if (failed.length) {
        promises = failed.map((rejected) => {
          return new Promise((resolve, reject) => {
            const asset: HTMLImageElement = new Image();
            asset.src = (rejected.reason as { source: string; key: string }).source as string;
            asset.onload = () => {
              if (cb) cb((rejected.reason as { source: string; key: string }).key);
              resolve(rejected.reason);
            };
            asset.onerror = () => reject(rejected.reason);
          });
        });
        continue;
      }
      success = true;
      break;
    } catch (err) {
      console.log(err);
    }
  }

  return success ? Promise.resolve() : Promise.reject();
};

export const isDevelopment = (): boolean => process.env.NODE_ENV === 'development';
export const isTesting = (): boolean => {
  return window.location.host.includes('testing');
};

export const getCssVariable = (cssVar: string): string => {
  return getComputedStyle(document.documentElement).getPropertyValue(cssVar);
};
export const calcBottomContainerHeight = (width: number, height: number): number => {
  if (width < height) {
    return height * (parseInt(getCssVariable('--bottom-height-percent-portrait'), 10) / 100);
  }
  return height * (parseInt(getCssVariable('--bottom-height-percent-landscape'), 10) / 100);
};

export const getFromMappedSymbol = <T>(map: Record<SlotId, { default: T; freespin?: T }>, id: SlotId): T =>
  isFreeSpinMode(setGameMode()) ? map[id as SlotId].freespin ?? map[id as SlotId].default : map[id as SlotId].default;

export const isBuyFeatureEnabled = (features: Features[] = []): boolean => {
  const freeSpinFeature = features.find((i) => i.id === 'freeSpins');

  return freeSpinFeature?.enabled || false;
};

export const normalizeBalance = (balance = 0): number => {
  return balance / 100;
};

export const normalizeCoins = (coins = 0): number => {
  return (coins * setCoinValue()) / 100;
};
export const showCurrency = (currency: string): boolean => {
  return currency !== 'FUN';
};

const shouldDisplayPhoenixAnticipation = (): boolean => {
  const num = Math.random();

  if (num <= 0.5) {
    return true;
  }
  return false;
};

export const checkPhoenixAnticipation = (placeBet: ISettledBet): boolean => {
  const showPhoenixAnticipation = shouldDisplayPhoenixAnticipation();
  const betAmount = normalizeCoins(setBetAmount());
  const normalizedWinAmount = normalizeCoins(placeBet.bet.result.winCoinAmount);

  // 200
  if (normalizedWinAmount >= 100 * betAmount && showPhoenixAnticipation) {
    return true;
  }
  return false;
};

export const queryParams = new URLSearchParams(window.location.search);
